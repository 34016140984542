<template>
    <div class="p-12 bg-cover bg-center" :style="{ backgroundImage: `url(${backgroundImage})` }">
        <div class="inset-0 flex flex-col items-center justify-center">
            <div class="text-surface-800 text-4xl font-bold leading-10 mb-8">Help center</div>
            <div class="flex items-center">
                <span class="relative mr-4">
                    <FaIcon
                        icon="far fa-search"
                        class="absolute top-2/4 -mt-2.5 left-3.5 text-xl text-surface-800 dark:text-surface-600"
                    />
                    <InputText
                        v-model="searchQuery"
                        placeholder="Search in video library"
                        class="pl-11 w-[360px] py-2.5"
                    />
                </span>
                <Button label="Search" rounded class="font-semibold" @click="handleSearch" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import backgroundImage from '@/assets/images/help-center-hero-img.png'

const emit = defineEmits<{
    (e: 'search', query: string): void
}>()

const searchQuery = ref('')

const handleSearch = () => {
    emit('search', searchQuery.value)
}
</script>

<style scoped>
.p-button-primary {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
</style>
